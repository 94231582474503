<script>
/* eslint-disable */
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import Layout from '../../layouts/main';

import {projectData} from './data-projects';

/**
 * Projects-list component
 */
export default {
  page: {
    title: 'Projects List',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      projectData,
      title: 'Projects List',
      items: [
        {
          text: 'Projects',
          href: '/',
        },
        {
          text: 'Projects List',
          active: true,
        },
      ],
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title"/>
    <div class="row">
      <div class="col-lg-12">
        <div>
          <div class="table-responsive">
            <table
              class="table project-list-table table-nowrap table-centered table-borderless align-middle"
            >
              <thead>
              <tr>
                <th scope="col" style="width: 100px">#</th>
                <th scope="col">Projects</th>
                <th scope="col">Due Date</th>
                <th scope="col">Status</th>
                <th scope="col">Team</th>
                <th scope="col">Action</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="project in projectData" :key="project.id">
                <td>
                  <img :src="`${project.image}`" alt class="avatar-sm"/>
                </td>
                <td>
                  <h5 class="text-truncate font-size-14">
                    <a class="text-dark" href="javascript: void(0);">{{
                        project.text
                      }}</a>
                  </h5>
                  <p class="text-muted mb-0">{{ project.subtext }}</p>
                </td>
                <td>{{ project.date }}</td>
                <td>
                    <span
                      :class="{
                        'bg-success': `${project.status}` === 'Completed',
                        'bg-warning': `${project.status}` === 'Pending',
                        'bg-danger': `${project.status}` === 'Delay',
                      }"
                      class="badge"
                    >{{ project.status }}</span
                    >
                </td>
                <td>
                  <div class="avatar-group">
                    <div class="avatar-group-item">
                      <a class="d-inline-block" href="javascript: void(0);">
                        <img
                          :src="`${project.users[0]}`"
                          alt
                          class="rounded-circle avatar-xs"
                        />
                      </a>
                    </div>
                    <div v-if="project.users[1]" class="avatar-group-item">
                      <a class="d-inline-block" href="javascript: void(0);">
                        <img
                          :src="`${project.users[1]}`"
                          alt
                          class="rounded-circle avatar-xs"
                        />
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <b-dropdown
                    class="card-drop"
                    menu-class="dropdown-menu-end"
                    right
                    toggle-class="p-0"
                    variant="white"
                  >
                    <template v-slot:button-content>
                      <i class="mdi mdi-dots-horizontal font-size-18"></i>
                    </template>
                    <b-dropdown-item href="javascript: void(0);"
                    >Action
                    </b-dropdown-item
                    >
                    <b-dropdown-item href="javascript: void(0);"
                    >Another action
                    </b-dropdown-item
                    >
                    <b-dropdown-item href="javascript: void(0);"
                    >Something else here
                    </b-dropdown-item
                    >
                  </b-dropdown>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-12">
        <div class="text-center my-3">
          <a class="text-success" href="javascript:void(0);">
            <i class="bx bx-loader bx-spin font-size-18 align-middle me-2"></i>
            Load more
          </a>
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
  </Layout>
</template>
