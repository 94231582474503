/* eslint-disable */
const projectData = [
  {
    id: 1,
    image: require('@/assets/images/companies/img-1.png'),
    text: 'New admin Design',
    subtext: 'It will be as simple as Occidental',
    users: [
      require('@/assets/images/users/avatar-2.jpg'),
      require('@/assets/images/users/avatar-1.jpg')],
    status: 'Completed',
    date: '15 Oct, 19',
    comment: 214,
  },
  {
    id: 2,
    image: require('@/assets/images/companies/img-2.png'),
    text: 'Brand logo design',
    subtext: 'To achieve it would be necessary',
    users: [require('@/assets/images/users/avatar-3.jpg')],
    status: 'Pending',
    date: '22 Oct, 19',
    comment: 183,
  },
  {
    id: 3,
    image: require('@/assets/images/companies/img-3.png'),
    text: 'New Landing Design',
    subtext: 'For science, music, sport, etc',
    users: [
      require('@/assets/images/users/avatar-5.jpg'),
      require('@/assets/images/users/avatar-4.jpg')],
    status: 'Delay',
    date: '13 Oct, 19',
    comment: 175,
  },
  {
    id: 4,
    image: require('@/assets/images/companies/img-4.png'),
    text: 'Redesign - Landing page',
    subtext: 'If several languages coalesce',
    users: [require('@/assets/images/users/avatar-6.jpg')],
    status: 'Completed',
    date: '14 Oct, 19',
    comment: 202,
  },
  {
    id: 5,
    image: require('@/assets/images/companies/img-5.png'),
    text: 'Skote Dashboard UI',
    subtext: 'Separate existence is a myth',
    users: [
      require('@/assets/images/users/avatar-7.jpg'),
      require('@/assets/images/users/avatar-8.jpg')],
    status: 'Completed',
    date: '13 Oct, 19',
    comment: 194,
  },
  {
    id: 6,
    image: require('@/assets/images/companies/img-6.png'),
    text: 'Blog Template UI',
    subtext: 'For science, music, sport, etc',
    users: [require('@/assets/images/users/avatar-6.jpg')],
    status: 'Pending',
    date: '24 Oct, 19',
    comment: 122,
  },
  {
    id: 7,
    image: require('@/assets/images/companies/img-3.png'),
    text: 'Multipurpose Landing',
    subtext: 'It will be as simple as Occidental',
    users: [require('@/assets/images/users/avatar-3.jpg')],
    status: 'Delay',
    date: '15 Oct, 19',
    comment: 214,
  },
  {
    id: 8,
    image: require('@/assets/images/companies/img-4.png'),
    text: 'App Landing UI',
    subtext: 'For science, music, sport, etc',
    users: [require('@/assets/images/users/avatar-4.jpg')],
    status: 'Completed',
    date: '11 Oct, 19',
    comment: 185,
  },
  {
    id: 9,
    image: require('@/assets/images/companies/img-2.png'),
    text: 'New admin Design',
    subtext: 'Their most common words.',
    users: [require('@/assets/images/users/avatar-5.jpg')],
    status: 'Completed',
    date: '12 Oct, 19',
    comment: 106,
  },
];

export {projectData};
